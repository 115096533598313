.text{
    color: #f54562;
    font-size: 1rem;
    ol{
        margin: 0;
        padding: 10px;
    }
    li{
        color: #f54562;
        line-height: 5px;
    }
}
div.text{
    padding-right: 25px;
}

.title{
    color: #f54562;
}

.chartist-tooltip.tooltip-show {
  opacity: 1;
  padding: 0.5em;
  border: 1px solid #eaedf2;
  margin-top: -10px;
}

.tooltip-show.chartist-tooltip:before {
  bottom: initial;
  top: 100%;
}
.chartist-tooltip {
  border: 0;
  padding: 0;
}

.chartist-tooltip:before {
  top: unset;
  bottom: 100%;
}

.section-question{
    width: 65%;
    position: absolute;
    text-align: center;
    background: linear-gradient(to bottom, transparent 0%, white 25%);
    bottom: 0px;
    z-index: 1000;
}

.section-disabled{
    pointer-events: none;
    cursor: pointer;
}
.section-disabled:hover{
    cursor:progress;
}

.send-icon{
    width: 50%;
    margin-left: -6%;
}

.chat-content{
    flex: 1;
    display: flex;
}
.chat_mascotte:hover{
    cursor: pointer;
}
.section-results{
    height: 100%;
    overflow: scroll;
    overflow-x: hidden;
    max-height: 85vh;
}

.section-results::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
}
.section-results::-webkit-scrollbar-thumb {
    background: #8888885d;
    border-radius: 4px;
}




.form-container {
    width: 100%;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    height: 100%;
    display: flex;
    flex-direction: column;
    /* max-width: 800px;
    margin: 50px auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease; */
}

.form-container:hover {
    /*transform: translateY(-5px);*/
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

/* .form-container h1 {
    text-align: center;
    margin-bottom: 30px;
    color: #f54562;
} */
.section-empty{
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 2rem;
    color: #f54562;
}


.form-container label {
    font-size: 18px;
    color: #333;
}
.section-separator{
    height: 30px;
    background: linear-gradient(to bottom, transparent 0%, white 75%);
}
.form-container textarea[type="text"] {
    padding: 10px 3% !important;
    padding-right: 50px !important;
    border: 1px solid #ccc;
    border-radius: 20px;
    font-size: 16px;
    width: 85%;
    max-width: 90%;
    height: 24px;
    max-height: 200px;
    margin-bottom: 2%;
    overflow-y: hidden;
    resize: none;
    background-color: white;
    background-clip: padding-box;
    align-items: center;
    justify-content: center;
    box-sizing: unset;
}

.form-container textarea[type="text"]::-webkit-scrollbar {
    width: 0px;
}

textarea:focus {
    outline: none;
}

.send-question {
    background-color: #f54562;
    color: #fff;
    border: none;
    border-radius: 50%;
    height: 52px;
    width: 52px;
    font-size: 25px;
    font-weight: 800; 
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: absolute;
    bottom: 28px;
    right: 3%;
}
.send-question:hover {
    animation: button-hover 0.05s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

.send-question:disabled {
    background-color: #ffced6;
}

.form-container button:disabled:hover {
    outline: 0 !important;
}

@keyframes button-hover {
    0% {
        outline: 1px solid #ffced6;
    }
    50% {
        outline: 2.5px solid #ffced6;
    }
    100% {
        outline: 5px solid #ffced6;
    }
}


/* .form-container button:hover {
    background-color: #0056b3;
} */
.image-user{
    width: 40px;
    height: 40px;
    background-color: #c7b9bb;
    border: none;
    border-radius: 50%;
    margin-left: 5%;
} 
.image-light{
    width: 40px;
    height: 40px;
    background-color: #f54562;
    border: none;
    border-radius: 50%;
    margin-left: 5%;
}
.result-container{
    box-sizing: unset;
    margin: 20px 0;
}
.result-container hr{
    width: 50%;
    color:#333;
    margin: 40px auto;
}
.message-owner-section{
    width: 100%;
    display: flex;
    align-items: center;
    background: linear-gradient(to right, transparent 0%, #f5f5f5 7%, #f5f5f5 92%, transparent 100%,);
    padding: 10px 0;
    font-size: 1.25rem;
}

.message-owner-section i{
    padding: 0 2.5%;
}

.message-content-section{
    margin-top: 20px;
    box-sizing: unset;
    width: 86%;
    padding: 20px 7%;
}
/* Answer Container */
.answer-container {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    font-weight: bold;
    padding: 20px;
    border-radius: 5px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    max-height: 50vh;
}

.answer-container p {
    color: #f54562;
    font-family: var(--bs-font-sans-serif);
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.6;
    margin-right: 20px;
    margin-bottom: 5px;
}

.answer-container.show-answer {
    transform: scale(1.02);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
.chart-container{
    width: 100%;
    padding: 50px 0;
}

.map-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
}

.map-results{
    border: 1px solid #d4d4d4;
    border-radius: 20px;
}

.title{
    flex-basis: 100%;
}

.text{
    align-self: flex-end;
}
/* app.css */

.explanation {
    font-size: 16px;
    color: #555;
    border-left: 3px solid #3498db;
    padding-left: 10px;
  }

.explanation-container{
    padding: 20px;
    color: #747474;
}

.sql-display pre{
    margin: 40px 5%;
    margin-top: 50px;
    border: 1px solid #b4b4b4;
    border-radius: 10px 10px 0 0;
}

.sql-display pre::-webkit-scrollbar{
    background-color: transparent;
    height: 3px;
    padding: 3px;
}
.sql-display pre::-webkit-scrollbar-thumb{
    background-color: #b4b4b4;
    border-radius: 10px;
    height: 3px;
}
.message-content-section-final {
    margin-top: 20px;
    box-sizing: unset;
    width: 86%;
    padding: 20px 7%;
    padding-bottom: 90px;
}
